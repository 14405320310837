import { Button, Card, message, Popconfirm, Space } from 'antd';
import React, { useState } from 'react';

import OrdersApi from '../../../../apis/order-management/recycle/RecycleOrdersApi';
import DeviceChecker from '../DeviceChecker';

export default function GroupOptions({ baseUrl, activeOrderId, setKey, services, isPaid, handleCancel }) {
    const [loadingObj, setLoadingObj] = useState({
        resendOrderReceivedMail: false,
        sendOrderReminder: false,
        markAsCompleted: false,
        markAsReturn: false,
        setAsUnPaid: false,
    });
    const [visibleDeviceChecker, setVisibleDeviceChecker] = useState(false);

    const resendOrderReceivedMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            resendOrderReceivedMail: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.sendResendOrderConfirmation(baseUrl, activeOrderId);
            message.success(response.data);
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
    }

    const sendOrderReminderHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            sendOrderReminder: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.sendOrderReminder(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
    }

    const markAsCompletedHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            markAsCompleted: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.markAsCompleted(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                markAsCompleted: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                markAsCompleted: false
            });
        }
    }

    const markAsReturnHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            markAsReturn: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.markAsReturn(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                markAsReturn: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                markAsReturn: false
            });
        }
    }

    const setAsUnPaidHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            setAsUnPaid: true,
        });

        try {
            if (!activeOrderId)
                throw new Error('Order id is required!');

            const response = await OrdersApi.setAsUnPaid(baseUrl, activeOrderId);
            message.success(response.success);
            setLoadingObj({
                ...loadingObj,
                setAsUnPaid: false
            });
            setKey(prevState => prevState + 1);
            handleCancel();
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                setAsUnPaid: false
            });
        }
    }

    return (
        <Card className='mb-4'>
            <Space align="center" size="middle" wrap>
                <Button type="dashed" danger onClick={resendOrderReceivedMailHandler} loading={loadingObj.resendOrderReceivedMail}>Resend Order Confirm Mail</Button>
                <Button type='primary' ghost onClick={sendOrderReminderHandler} loading={loadingObj.sendOrderReminder}>Send Order Reminder</Button>
                {!isPaid &&
                    <Popconfirm
                        title="Are you sure to complete this order?"
                        onConfirm={markAsCompletedHandler}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger ghost loading={loadingObj.markAsCompleted}>Mark as Completed</Button>
                    </Popconfirm>
                }
                <Popconfirm
                    title="Are you sure to return this order?"
                    onConfirm={markAsReturnHandler}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type='primary' danger ghost loading={loadingObj.markAsReturn}>Mark as Return</Button>
                </Popconfirm>

                {isPaid &&
                    <Popconfirm
                        title="Are you sure to unpaid this order?"
                        onConfirm={setAsUnPaidHandler}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger loading={loadingObj.setAsUnPaid}>Set as Unpaid</Button>
                    </Popconfirm>
                }
                <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>
            </Space>
            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrderId}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }
        </Card>
    )
}
