import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shippingOrdersUrgent: []
};

const shippingOrdersUrgentSlice = createSlice({
    name: 'shippingOrdersUrgent',
    initialState,
    reducers: {
        addShippingOrderUrgent: (state, action) => {
            state.shippingOrdersUrgent.push(action.payload);
        },
        deleteShippingOrderUrgent: (state, action) => {
            state.shippingOrdersUrgent = state.shippingOrdersUrgent.filter(order => order.id !== action.payload);
        }
    }
});

export const { addShippingOrderUrgent, deleteShippingOrderUrgent } = shippingOrdersUrgentSlice.actions;

export default shippingOrdersUrgentSlice.reducer;
