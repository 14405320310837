import { Button, Card, message, Space } from 'antd';
import React, { useState } from 'react';

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/OrdersApi';
import DeviceChecker from '../DeviceChecker';
import useSideMenuBadgeCount from '../../../../hooks/useSideMenuBadgeCount';

export default function GroupOptions({ activeOrder, baseUrl, activeOrderId, services, setKey, handleCancel }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        resendOrderReceivedMail: false,
        exportDelivery: false,
        unpaidMail: false,
        deviceCheck: false
    });
    const [visibleDeviceChecker, setVisibleDeviceChecker] = useState(false);

    const { mutateCountKey } = useSideMenuBadgeCount();

    const checkoutFailedHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            unpaidMail: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.sendUnpaidMail(baseUrl, activeOrderId);
            message.success(response.data);
            setLoadingObj({
                ...loadingObj,
                unpaidMail: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                unpaidMail: false
            });
        }
    }

    const resendOrderReceivedMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            resendOrderReceivedMail: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.sendResendOrderConfirmation(baseUrl, activeOrderId);
            message.success(response.data);
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                resendOrderReceivedMail: false
            });
        }
    }

    const exportDeliveryHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportDelivery: true
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.exportDelivery(baseUrl, activeOrderId);
            message.success(response.data);
            setLoadingObj({
                ...loadingObj,
                exportDelivery: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportDelivery: false
            });
        }
    }

    const urgentHandler = async (value) => {
        setLoadingObj({
            ...loadingObj,
        });

        try {
            if (!activeOrderId)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.urgentOrder(baseUrl, activeOrderId, value);
            message.success(response.data);
            setKey(prevState => prevState + 1);

            // set update the count key of side menu
            mutateCountKey();

            handleCancel();
            setLoadingObj({
                ...loadingObj,
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportDelivery: false
            });
        }
    }

    return (
        <Card className='mb-4'>
            {activeOrder.is_paid === 1 &&
                <Space align="center" size="middle" wrap>
                    <Button type="dashed" danger onClick={resendOrderReceivedMailHandler} loading={loadingObj.resendOrderReceivedMail}>Resend Order Confirm Mail</Button>
                    <Button type="dashed" onClick={exportDeliveryHandler} loading={loadingObj.exportDelivery}>Ex. Delivery</Button>
                    <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>
                    <Button danger onClick={() => urgentHandler(activeOrder?.is_urgent === 0)}>{activeOrder?.is_urgent === 0 ? `Mark` : `Un-Mark`} Urgent</Button>
                </Space>
            }

            {activeOrder.is_paid === 0 &&
                <Space align="center" size="middle" wrap>
                    <Button danger onClick={checkoutFailedHandler} loading={loadingObj.unpaidMail} >Checkout Failed</Button>
                    <Button onClick={() => setVisibleDeviceChecker(true)}>Print Label</Button>
                    <Button danger onClick={() => urgentHandler(activeOrder?.is_urgent === 0)}>{activeOrder?.is_urgent === 0 ? `Mark` : `Un-Mark`} Urgent</Button>
                </Space>
            }

            {visibleDeviceChecker &&
                <DeviceChecker
                    visible={visibleDeviceChecker}
                    handleCancel={() => setVisibleDeviceChecker(false)}
                    activeOrderId={activeOrderId}
                    setKey={setKey}
                    baseUrl={baseUrl}
                    services={services}
                    maskClosable
                />
            }
        </Card>
    )
}
