import { CloseCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import dayjs from 'dayjs';
dayjs.locale('en-gb');
import React from 'react';
import { useSelector } from "react-redux";
import styles from '../PromoCode.module.scss';
import PromoCodeApi from '../../../apis/order-management/PromoCodeApi';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler }) {

    const baseUrl = useSelector((state) => state.auth.base_url)

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSelectedRows()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteSelectedRows = async () => {
        const selectFirstItem = selectedRows[0];
        await PromoCodeApi.deleteOne(baseUrl, selectFirstItem.id);
        message.success('Customers is Deleted!');
        setIndex(prevIndex => prevIndex + 1)
        rowsUnCheckHandler()

    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        type="primary"
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>
                    {selectedRowKeys.length === 1 &&

                        <Button
                            danger
                            onClick={showConfirm}
                            className={styles.button}
                        >
                            <CloseCircleOutlined />
                            Delete
                        </Button>
                    }
                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
