import React, { useState, useMemo } from 'react';
import { Button, Col, List, message, Row, Typography } from 'antd';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';
import { EditOutlined, MoneyCollectFilled } from '@ant-design/icons';
import DirectOrdersApi from '../../../../apis/order-management/direct-order/DirectOrdersApi';
import { getPayStatusByValue } from '../../util/common';
import StatusBadge from '../../../../components/StatusBadge';

const { Text } = Typography;

export default function MoreInfo({ activeOrder, baseUrl, setActiveOrder, setActiveKey, setVisiblePayment }) {

    const handleUpdate = async (fieldType, newValue) => {
        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const updatedOrder = await DirectOrdersApi.updateOne(baseUrl, activeOrder.id, {
                fieldType: fieldType,
                newValue: newValue
            });
            if (updatedOrder) {
                setActiveKey(prevState => prevState + 1);
                setActiveOrder(prevOrder => ({
                    ...prevOrder,
                    [fieldType]: newValue
                }));
                message.success(`${fieldType} Updated!`);
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        }
    };


    const [showCost, setShowCost] = useState(true);
    const [showPayment, setShowPayment] = useState(true);

    const toggleCost = () => {
        setShowCost(!showCost);
    };
    const togglePayment = () => {
        setShowPayment(!showPayment);
    };

    const dataSourceCustomer = useMemo(() => [
        <ListItem
            key={0}
            keyText="Name : "
            valueText={`${activeOrder?.user?.customer_name}`}
            valueCopyable
            icon={<EditOutlined />}
            fieldType="customer_name"
            editable={true}
            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
        />,
        <ListItem
            key={1}
            keyText="E-Mail : "
            valueText={activeOrder?.user?.email || 'N/A'}
            valueCopyable={!!activeOrder?.user?.email}
            icon={<EditOutlined />}
            fieldType="email"
            editable={true}
            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
        />,
        <ListItem
            key={2}
            keyText="Phone : "
            valueText={activeOrder?.user?.phone_number || 'N/A'}
            valueCopyable={!!activeOrder?.user?.phone_number}
            icon={<EditOutlined />}
            fieldType="phone_number"
            editable={true}
            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
        />
    ], [activeOrder, handleUpdate]);

    const statusObj = useMemo(() => getPayStatusByValue(activeOrder.is_paid), [activeOrder.is_paid]);

    const dataSourceOrderSummary = useMemo(() => [
        <ListItem
            key={0}
            keyText="Order # :"
            valueText={activeOrder.order_no}
            valueCopyable
        />,
        <ListItem
            key={1}
            keyText="Pay Status :"
            valueText={<StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} />}
        />,
        <ListItem
            key={2}
            keyText="Repair Cost"
            valueText={activeOrder?.repair_cost}
            showCost={showCost}
            activeOrder={activeOrder}
            type="cost"
            onToggleDetails={toggleCost}
        />,
        <ListItem
            key={3}
            keyText="Deposit"
            activeOrder={activeOrder}
            type="payment"
            showPayment={showPayment}
            valueText={activeOrder?.deposit_amount}
            onToggleDetails={togglePayment}
        />,
        <ListItem
            key={4}
            keyText="Balance Due"
            bgcolor={parseInt(activeOrder?.due_amount) === 0 ? "red" : "green"}
            valueText={parseFloat(activeOrder?.due_amount).toFixed(2)}
        />,
        parseFloat(activeOrder?.due_amount) !== 0 && (
            <Button
                key={5}
                onClick={() => setVisiblePayment(true)}
                size='small'
                danger
            >
                <MoneyCollectFilled /> Add Payment
            </Button>
        ),
    ], [activeOrder, showCost, showPayment, statusObj]);

    return (
        <>
            <Row gutter={16} className={styles.model_card_row}>


                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>Customer Details</Text>}
                        bordered
                        dataSource={dataSourceCustomer}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </Col>


                {activeOrder.user &&
                    <Col span={6}>
                        <List
                            size="small"
                            header={<Text>Order Summary</Text>}
                            bordered
                            dataSource={dataSourceOrderSummary}
                            renderItem={(item, idx) => (
                                <List.Item style={{ backgroundColor: idx === 4 ? (parseInt(activeOrder?.due_amount) === 0 ? "#8d8" : "#f29f9f") : 'transparent' }}>
                                    {item}
                                </List.Item>
                            )}
                        />
                    </Col>
                }
            </Row>

        </>
    )
}

