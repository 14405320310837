import { useEffect, useState } from 'react';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';

import OrdersApi from "../apis/order-management/OrdersApi";
import { setCountKey } from '../store/slices/menuBadgeCountKeySlice';

const useSideMenuBadgeCount = () => {
  const [counts, setCounts] = useState({
    urgentOrder: 0,
  });

  const dispatch = useDispatch();
  const baseUrl = useSelector((state) => state.auth.base_url);
  const getUrgentOrderCountKey = useSelector((state) => state.menuBadgeCountKey?.urgentOrder);

  const getCounts = async () => {
    const endpoints = [
      OrdersApi.list(baseUrl, qs.stringify({ is_urgent: true })), // Get urgent orders count
    ]

    const [orderData] = await Promise.all(endpoints);
    setCounts({
      urgentOrder: orderData?.data?.total || 0,
    });
  };

  useEffect(() => {
    getCounts();
  }, [baseUrl, getUrgentOrderCountKey]);

  const mutateCountKey = () => {
    dispatch(setCountKey({ key: 'urgentOrder' }));
  }

  return { counts, mutateCountKey };

};

export default useSideMenuBadgeCount;
