import appApi from "../index";
import qs from 'qs';

const endpoint = "promo-code";

const list = (url = null, params) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/${endpoint}`, { params: { ...qs.parse(params) } })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};



const deleteOne = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .delete(`/${endpoint}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const autocompleteEmail = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .get(`/autocomplete-email?term=${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};

const expire = (url = null, id) => {
  return new Promise((resolve, reject) => {
    appApi(url)
      .post(`/${endpoint}/expire-code/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};
 
const updateCustomer = (url = null, formData) => {
  return new Promise((resolve, reject) => {
      appApi(url)
          .post(`/${endpoint}`, formData)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              if (error.response) {
                  reject(error.response.data);
              } else if (error.request) {
                  reject(error);
              } else {
                  reject(error);
              }
          });
  });
};
export default {
  list,
  expire,
  deleteOne, 
  autocompleteEmail, 
  updateCustomer,
};
