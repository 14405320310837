import { Form, Select, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { getAllRevisedOfferStatus } from '../../../util/common';
import EditableContext from './EditableContext';

const { Option } = Select;

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSaveRevisedOfferStatus,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [loadingObj, setLoadingObj] = useState({
        updateOrder: false,
    });
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        let isCancelled = false

        if (editing) {
            inputRef.current.focus();
        }

        return () => {
            isCancelled = true
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        setLoadingObj({ ...loadingObj, updateOrder: true });
        try {
            const values = await form.validateFields();
            toggleEdit();

            if (dataIndex === 'status') {
                await handleSaveRevisedOfferStatus({
                    id: record.id,
                    ...values,
                });
            }

            setLoadingObj({ ...loadingObj, updateOrder: false });
        } catch (errInfo) {
            setLoadingObj({ ...loadingObj, updateOrder: false });
            message.error('Something went wrong!');
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <>
                {dataIndex === 'status' &&
                    <Form.Item
                        className='m-0'
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <Select
                            ref={inputRef}
                            onChange={save}
                            placement='topLeft'
                        >
                            {getAllRevisedOfferStatus.map((item, idx) => {
                                console.log('item', item, record.status, record.is_update);
                                return(
                                <Option key={idx} value={item.value} disabled={((record.status === 1 || record.status === 2) &&  item.value === 3)}>{item.label}</Option>
                            )})}
                        </Select>
                    </Form.Item>
                }
            </>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;