import { message } from "antd";
import dayjs from 'dayjs';

import { IDocument, IsExtensionInstalled, IPrinter } from "../components/bpac";
import { SiteOptions } from "../components/layout/util/common";

const PRINTER_NAME = 'Brother QL-700';

function checkExtensionInstalled() {
    if (!IsExtensionInstalled()) {
        const agent = window.navigator.userAgent.toLowerCase();
        const isChrome = (agent.indexOf('chrome') !== -1) && (agent.indexOf('edge') === -1) && (agent.indexOf('opr') === -1)
        if (isChrome)
            window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank');

        return false;
    }
    return true;
}

async function doPrint(obj, copies) {
    const objDoc = IDocument;

    const template = await objDoc.Open("https://media.fixmybrokenscreen.co.uk/software/assets/fix-my-label2.lbx"); // most of the functions from the SDK return true/false for success

    if (!template) {
        throw new Error('Can\'t find any template file.');
    }

    await objDoc.SetPrinter(PRINTER_NAME, true);

    // Set the text in a Text object inside your template file
    (await objDoc.GetObject("objSource")).Text = obj.source;
    (await objDoc.GetObject("objDArrival")).Text = obj.dArrival;
    (await objDoc.GetObject("objUsername")).Text = obj.username;
    (await objDoc.GetObject("objDevice")).Text = obj.device;
    await objDoc.SetBarcodeData(await objDoc.GetBarcodeIndex("objQrCode"), obj.qrCode);

    // Start the print
    await objDoc.StartPrint('', 0);
    await objDoc.PrintOut(copies, 0);
    await objDoc.EndPrint();
    return await objDoc.Close();
}

async function printAFile(baseUrl, order, cb1 = () => { }, cb2 = () => { }, copies = 3) {
    if (!checkExtensionInstalled()) {
        cb2();
        return;
    }

    try {
        if (!IPrinter.prototype.IsPrinterOnline(PRINTER_NAME)) {
            throw new Error('Printer is offline, Please turn on the printer');
        }

        if (!order) throw new Error('Label printing is failed, order is not found.');

        if (typeof cb1 === 'function') cb1();

        let orderItems = order.check_order_items?.length > 0 ? order.check_order_items : [];
        if (orderItems.length === 0) {
            throw new Error('Label printing is failed, order items are not found.');
        }

        const { brand } = SiteOptions.find(item => item.value === baseUrl) || { brand: "FMBS" };

        orderItems.forEach((item) => {
            let { product_name, short_note, qty } = item;

            for (let i = 1; i <= qty; i++) {
                // console.log({
                //     source: brand,
                //     dArrival: dayjs(order.date_of_arrival || order.updated_at).format("DD/MM/YYYY"),
                //     username: order.order_no + ' / ' + short_note,
                //     device: product_name,
                //     qrCode: order.order_no,
                // });

                doPrint({
                    source: brand,
                    dArrival: dayjs(order.date_of_arrival || order.updated_at).format("DD/MM/YYYY"),
                    username: order.order_no + ' / ' + short_note,
                    device: product_name,
                    qrCode: order.order_no,
                }, copies);
            }
        });

        if (cb2 instanceof Function) { cb2(); }
        message.success('Label is printed!');
    }
    catch (e) {
        if (cb2 instanceof Function) { cb2(); }
        message.error(e.message ?? 'Label printing is failed, something went wrong.');
    }
}

export { doPrint, checkExtensionInstalled, printAFile };