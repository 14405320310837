import { Button, Card, Col, Form, message, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/OrdersApi';
import { ConfirmModal } from '../../../../components/ConfirmModal';
import { getAllOrderStatus } from '../../util/common';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

const { Option } = Select;

export default function OrderUpdate({ activeOrder, handleReset, isNavigable, baseUrl }) {
    let history = useHistory();

    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    const [loadingObj, setLoadingObj] = useState({
        updateOrder: false,
    });

    const onFinish = async (values) => {
        setLoadingObj({ ...loadingObj, updateOrder: true });
        try {
            if (!activeOrder.id)
                throw new Error('Something went wrong!');

            let sendDiagnosticEmail = false;
            if ((values.status === 2) && activeOrder?.payment_type === 0) {
                try {
                    await ConfirmModal({
                        title: 'Diagnostic Email Confirmation',
                        content: 'Do you want to send the diagnostic email to the customer?',
                        okText: 'Send Diagnostic Email',
                        cancelText: 'Send Device Received Email',
                        onOk: async () => {
                            sendDiagnosticEmail = true;
                        },
                        onCancel: () => {
                            //
                        }
                    });
                } catch (error) {
                    // Handle rejection (if 'No' is selected)
                }
            }

            let isGenerateLabel = false;
            if (values.status === 3 || values.status === 11) {
                try {
                    await ConfirmModal({
                        title: 'Royal mail shipment label',
                        content: 'Do you want to generate the label for royal mail shipment?',
                        onOk: async () => {
                            isGenerateLabel = true;
                        },
                        onCancel: () => {
                            //
                        }
                    });
                } catch (error) {
                    // Handle rejection (if 'No' is selected)
                }
            }

            const updatedOrder = await OrdersApi.updateOne(baseUrl, activeOrder.id, {
                status: values.status,
                is_active: 1,
                order_type: 'repair',
                send_diagnostic_email: sendDiagnosticEmail ? 1 : 0
            });

            if (updatedOrder) {
                message.success(`${activeOrder?.order_no} - Order is updated!`);
                setLoadingObj({ ...loadingObj, updateOrder: false });
                handleReset();

                // when change 'complete' order status, should be trigger royal mail shipment label.
                if (values.status === 3 && isGenerateLabel) {
                    const hide = message.loading('Shipment label is generating..', 0);
                    const response = await OrdersApi.royalMailOrder(baseUrl, activeOrder.id);
                    hide();
                    if (response) {
                        message.success('Order was exported to Royal Mail!');
                    }
                }

                if (isNavigable) {
                    history.goBack();
                }
            }
        } catch (error) {
            setLoadingObj({ ...loadingObj, updateOrder: false });
            message.error('Something went wrong!');
        }
    }

    return (
        <Card>
            <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    status: activeOrder.status
                }}
            >
                <Row gutter={16} align="middle">
                    <Col span={16}>
                        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                            <Select>
                                {getAllOrderStatus.slice(1).map((item, idx) => (
                                    <Option key={idx} value={item.value} disabled={activeOrder.status === 6 || item.value === 6}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={styles.modelBtn}>
                            <Button size='small' className={styles.submitBtn} type="primary" htmlType="submit" loading={loadingObj.updateOrder}>
                                Update
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
