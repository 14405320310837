import { CloseOutlined, ExclamationCircleOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, message, Drawer, Typography, Radio, Upload } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from "react-redux";
import ProductCategoryApi from '../../../../apis/settings/ProductCategoryApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeCategory, handleReset, designation, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    console.log(activeCategory);

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const onFinish = async (values) => {
        try {

            const formData = new FormData();
            for (const key in values) {
                if (key === 'image' && values[key]) {
                    formData.append(key, values[key].file);
                } else if (key === 'image_thumb' && values[key]) {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }

            if (activeCategory?.id) {
                formData.append('id', activeCategory.id);
            }

            try {

                const updateCategory = await ProductCategoryApi.createCategory(baseUrl, formData);
                if (updateCategory) {
                    message.success('Category is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeCategory.id]);

        try {
            const deleteCategory = await ProductCategoryApi.deleteCategory(baseUrl, formData, activeCategory.id);
            if (deleteCategory) {
                message.success('Category is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeCategory.emp_name} - ${activeCategory.emp_slug}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }


    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    const [isImage, setIsImage] = useState(false);
    const [isImageThumbnail, setIsImageThumbnail] = useState(false);

    const handledeleteImage = (value) => {
        console.log(value);
    };
    const [editorData, setEditorData] = useState("");

    const handleChangeName = (e) => {
        const slugName = e.target.value;
        form.setFieldsValue({
            slug: slugName.toLowerCase().replace(/\s+/g, '-'),
        });
    }

    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Product Category Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='delivery-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeCategory?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeCategory && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="delivery-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    name: activeCategory?.name,
                    slug: activeCategory?.slug,
                    aslug: activeCategory?.aslug,
                    rank: activeCategory?.rank,
                    meta_title: activeCategory?.seo_meta?.[0]?.meta_title ?? null,
                    meta_description: activeCategory?.seo_meta?.[0]?.meta_description ?? null,
                    is_priority: activeCategory?.is_priority ?? 0,
                    is_insured_postage: activeCategory?.is_insured_postage ?? 0,
                    is_sending_postage: activeCategory?.is_sending_postage ?? 0,
                    is_apple_device: activeCategory?.is_apple_device ?? 0,
                    content: activeCategory?.content,
                }}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>

                            <Col span={8}>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Category Name' onChange={handleChangeName} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="slug" label="Slug" rules={[{ required: true }]}>
                                    <Input placeholder='Slug' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="aslug" label="A_Slug" rules={[{ required: true }]}>
                                    <Input placeholder='Enter the A_Slug' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {activeCategory?.image_url && !isImage ? (
                                    <>
                                        <div>
                                            <Text>Image</Text><br />
                                            <Link
                                                to={{ pathname: activeCategory.image_url }}
                                                target="_blank"
                                                className="btn-sign-in"
                                            >
                                                view <EyeOutlined />
                                            </Link>
                                            <Button type="primary" className='mx-4' onClick={() => setIsImage(true)}>Change</Button>
                                        </div>
                                    </>
                                ) : (
                                    <Form.Item name="image" label="Image" rules={[{ required: true }]}>
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                        >
                                            <Button type="primary" icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8}>
                                {activeCategory?.image_thumb_url && !isImageThumbnail ? (
                                    <>
                                        <div className='mb-5'>
                                            <Text>Image Thumbnail</Text><br />
                                            <Link
                                                to={{ pathname: activeCategory.image_thumb_url }}
                                                target="_blank"
                                                className="btn-sign-in"
                                            >
                                                view <EyeOutlined />
                                            </Link>
                                            <Button type="primary" className='mx-4' onClick={() => setIsImageThumbnail(true)}>Change</Button>
                                        </div>
                                    </>
                                ) : (
                                    <Form.Item name="image_thumb" label="Image Thumbnail" rules={[{ required: true }]} labelCol={{ span: 24 }}>
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                        >
                                            <Button type="primary" icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                )}
                            </Col>

                            <Col span={8}>

                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="is_priority"
                                    label="Enable the Priority Service ?"
                                    rules={[{ required: true, message: 'Please select Yes or No' }]}
                                    labelCol={{ span: 24 }}
                                    initialValue="0"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="is_apple_device"
                                    label="Is that an Apple Product ?"
                                    rules={[{ required: true, message: 'Please select Yes or No' }]}
                                    labelCol={{ span: 24 }}
                                    initialValue="0"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="is_insured_postage"
                                    label="Enable the Postage - Receiving Your Device After Repair ?"
                                    rules={[{ required: true, message: 'Please select Yes or No' }]}
                                    labelCol={{ span: 24 }}
                                    initialValue="0"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="is_sending_postage"
                                    label="Enable the Postage - Sending Your Device to Us ?"
                                    rules={[{ required: true, message: 'Please select Yes or No' }]}
                                    labelCol={{ span: 24 }}
                                    initialValue="0"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="meta_title" label="Meta Title" rules={[{ required: true }]}>
                                    <Input placeholder='Enter Meta Title' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="meta_description" label="Meta Description" rules={[{ required: true }]} labelCol={{ span: 24 }}>
                                    <Input placeholder='Enter Meta Description' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="editor1" label="Content">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                            console.log({ event, editor, data });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="good_images[]" label="Good images" labelCol={{ span: 24 }}>
                                    <Input type='file' placeholder='Upload here' multiple />
                                </Form.Item>
                                {activeCategory?.good_images && (() => {
                                    let goodImagesArray = [];
                                    goodImagesArray = JSON.parse(activeCategory.good_images);

                                    return goodImagesArray.length > 0 ? (
                                        <div>
                                            {goodImagesArray.map((image, idx) => (
                                                <div
                                                    className='d-flex justify-start py-1'
                                                    key={idx}
                                                >
                                                    <Link
                                                        key={idx}
                                                        to={{ pathname: image }}
                                                        target="_blank"
                                                        className="btn-sign-in"
                                                        style={{ display: 'block' }}
                                                    >
                                                        view Image {idx + 1} <EyeOutlined onClick={() => handledeleteImage()} />
                                                    </Link>
                                                    <CloseOutlined className='mx-2 text-danger' />
                                                </div>
                                            ))}
                                        </div>
                                    ) : null;
                                })()}
                            </Col>
                            <Col span={8}>
                                <Form.Item name="poor_images[]" label="Poor front image" labelCol={{ span: 24 }}>
                                    <Input type='file' placeholder='Upload here' multiple />
                                </Form.Item>
                                {activeCategory?.poor_images && (() => {
                                    let poor_imagesArray = [];
                                    poor_imagesArray = JSON.parse(activeCategory.poor_images);

                                    return poor_imagesArray.length > 0 ? (
                                        <div>
                                            {poor_imagesArray.map((image, idx) => (
                                                <>
                                                    <div className='d-flex justify-start py-1'>
                                                        <Link
                                                            to={{ pathname: image }}
                                                            target="_blank"
                                                            className="btn-sign-in"
                                                            style={{ display: 'block' }}
                                                        >
                                                            View Image {idx + 1} <EyeOutlined />
                                                        </Link>
                                                        <CloseOutlined className='mx-2 text-danger' />
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    ) : null;
                                })()}
                            </Col>
                            <Col span={8}>
                                <Form.Item name="faulty_images[]" label="Faulty front image" labelCol={{ span: 24 }}>
                                    <Input type='file' placeholder='Upload here' multiple />
                                </Form.Item>
                                {activeCategory?.faulty_images && (() => {
                                    let faulty_imagesArray = [];
                                    faulty_imagesArray = JSON.parse(activeCategory.faulty_images);

                                    return faulty_imagesArray.length > 0 ? (
                                        <div>
                                            {faulty_imagesArray.map((image, idx) => (
                                                <div
                                                    className='d-flex justify-start py-1'
                                                    key={idx}
                                                >
                                                    <Link
                                                        to={{ pathname: image }}
                                                        target="_blank"
                                                        className="btn-sign-in"
                                                        style={{ display: 'block' }}
                                                    >
                                                        View Image {idx + 1} <EyeOutlined />
                                                    </Link>
                                                    <CloseOutlined className='mx-2 text-danger' />
                                                </div>
                                            ))}
                                        </div>
                                    ) : null;
                                })()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
