import { ExclamationCircleOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Modal, Row, Select, message, Drawer, Radio, Typography, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import servicesApi from '../../../../apis/settings/servicesApi';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
    layout: "vertical"
};

export default function QuickEdit({ visible, handleCancel, activeService, handleReset, categoryCondition, roles, setIndex }) {
    const validateMessages = {
        required: '${label} is required!',
    };

    console.log(activeService);
    const [editorData, setEditorData] = useState((activeService?.content) ?? "");

    const baseUrl = useSelector((state) => state.auth.base_url)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const onFinish = async (values) => {
        try {

            const formData = new FormData();

            for (const key in values) {
                if (key === 'image' && values[key]) {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }
            if (activeService?.id) {
                formData.append('id', activeService.id);
            }

            formData.append('content', editorData);

            try {

                const updateServices = await servicesApi.createServices(baseUrl, formData);
                if (updateServices) {
                    message.success('Services is Updated!');
                    setIndex(prevIndex => prevIndex + 1);
                    form.resetFields();
                    handleReset();
                }
            } catch (error) {
                message.error('Something went wrong!');
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    };

    const handleDeleteUser = async () => {
        const formData = new FormData();
        formData.append('selectedRowIds', [activeService.id]);

        try {
            const deleteServices = await servicesApi.deleteServices(baseUrl, formData, activeService.id);
            if (deleteServices) {
                message.success('Services is Deleted!');
                setIndex(prevIndex => prevIndex + 1)
                form.resetFields();
                handleReset();
            }
        } catch (error) {
            message.error('Something went wrong!');
        }
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${activeService.emp_name} - ${activeService.emp_slug}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser();
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }

    const [isImage, setIsImage] = useState(false);

    const handleUploadChange = (info) => {
        const { fileList } = info;

        form.setFieldsValue({
            image: fileList[0] || null,
        });
    };
    return (
        <Drawer
            push={false}
            key="notification-details-model"

            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text>Services Form</Text>

                    <div className='d-flex'>
                        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
                        <Button
                            className='mx-2'
                            form='services-update'
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {activeService?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            footer={[
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeService && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button key="delete" danger onClick={showConfirm}>Delete</Button>
                        }
                    </Col>
                </Row>
            ]}
        >
            <Form
                {...layout}
                form={form}
                id="services-update"
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                initialValues={{
                    type: activeService?.type ?? "service",
                    warranty_period: activeService?.warranty_period ?? "365",
                    recycle_option: activeService?.recycle_option ?? 1,
                    name: activeService?.name ?? null,
                    description: activeService?.description ?? null,
                    content: activeService?.content ?? null,
                    alias: activeService?.alias ?? null,
                    short_code: activeService?.short_code ?? null,
                    repair_condition_type: activeService?.repair_condition_type ? parseInt(activeService?.repair_condition_type) : 1,
                }}
            >
                <Row gutter={[24, 0]}>

                    <Col span={23}>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item name="type" label="Type" labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                    >
                                        <Option value="service">Service</Option>
                                        <Option value="accessory">Accessory</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input placeholder='Service Name' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="alias" label="alias" rules={[{ required: true }]}>
                                    <Input placeholder='Alias' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {activeService?.image_url && !isImage ? (
                                    <>
                                        <div>
                                            <Text>Image</Text><br />
                                            <Link
                                                to={{ pathname: activeService?.image_url }}
                                                target="_blank"
                                                className="btn-sign-in"
                                            >
                                                view <EyeOutlined />
                                            </Link>
                                            <Button type="primary" className='mx-4' onClick={() => setIsImage(true)}>Change</Button>
                                        </div>
                                    </>
                                ) : (
                                    <Form.Item name="image" label="Image" rules={[
                                        {
                                            required: !activeService?.image_url,
                                            message: 'Please upload an image or ensure the existing image is valid.',
                                        },
                                    ]}>
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            onChange={handleUploadChange}
                                        >
                                            <Button type="primary" icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8}>
                                <Form.Item name="short_code" label="Short Code">
                                    <Input placeholder='Short Code' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="description" label="Description">
                                    <Input.TextArea placeholder="Enter Description" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="recycle_option"
                                    label="Is this applicable for recycle service ?"
                                    rules={[{ required: true, message: 'Please select Yes or No' }]}
                                    labelCol={{ span: 24 }}
                                    initialValue="0"
                                >
                                    <Radio.Group>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="warranty_period" label="Warranty Periodod" labelCol={{ span: 24 }}>
                                    <Select
                                        optionLabelProp="children"
                                    >
                                        <Option value="365">365 Days (12 Months)</Option>
                                        <Option value="90">90 Days</Option>
                                        <Option value="0">Not applicable</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="repair_condition_type" label="Repair Condition Type" labelCol={{ span: 24 }}>

                                    <Select
                                        optionLabelProp="children"
                                        placeholder="Select Condition"
                                    >
                                        {(categoryCondition?.data ?? []).map((item, idx) => (
                                            <Option key={idx} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name="content_old" label="Content" labelCol={{ span: 24 }}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
